<template>
  <div>
    <gl-title title="开票管理"></gl-title>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" min-width="30"></el-table-column>
        <el-table-column prop="invoiceTitle" label="抬头" show-overflow-tooltip></el-table-column>
        <el-table-column prop="invoiceOnly" label="公式唯一识别" show-overflow-tooltip></el-table-column>
        <el-table-column prop="invoiceNumber" label="发票编号" show-overflow-tooltip></el-table-column>
        
        <el-table-column prop="status" label="开票状态">
          <template slot-scope="{row}">
            <span v-if="row.status == 1" style="color: #67c23a">已开票</span>
            <span v-else>未开票</span>
          </template>
        </el-table-column>
        <el-table-column prop="receiveType" label="接收方式" show-overflow-tooltip>
          <template slot-scope="{row}">
            <span v-if="row.receiveType == 1">手机</span>
            <span v-else>邮箱</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="sort" label="开票金额"></el-table-column> -->
        <el-table-column prop="createTimeStart" label="申请时间" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="mClickAction('mUpdateVisible', scope.row.noticeId)"> 编辑 </el-button>
            <el-button type="text" @click="mClickAction('mPreviewVisible', scope.row.noticeId)"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import {invoiceList} from '@/api/backendall/finance'
export default {
  mixins: [ListMixin],
  mounted(){
    this.mGetListFun = invoiceList
    this.mGetList()
  },
}
</script>

<style>

</style>